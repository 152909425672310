<template>
  <div :id="fromBuilder ? 'dropZone' : ''"
    height="100%" class="pa-1"
    @dragover="allowDrop($event, 'dropZone')" 
    @dragleave="removeStyle('dropZone')" 
    @drop="fieldDroped('dropZone')">
    <v-form ref="form" v-if="fields && fields.length">
      <v-row class="ma-3" v-if="!loading">
        <draggable v-model="fields" class="row" :options="{ disabled : !Boolean(fromBuilder) }">
          <template v-for="(field, index) in fields">
            <v-col v-if="fromPanel ? true : !field.panelId" :key="index" :cols="field.cols ? field.cols : 3"
              @mouseover="hoverOnField = field._id">
              <template v-if="field.type === 'TITLE'">
                <v-card-title>{{field.label}}</v-card-title>
              </template>
              <template v-if="field.type === 'DIVIDER'">
                <v-divider></v-divider>
              </template>
              <template v-if="field.type === 'TEXT'">
                <v-text-field 
                  v-model="modelObj[field.model]"
                  outlined
                  dense
                  :rules="field.isRequired ? setValidation('requiredValidation') : []"
                  :label="field.label" 
                  :placeholder="field.label" 
                  :disabled="field.disabled"
                  :readonly="field.readonly">
                </v-text-field>
              </template>
              <template v-if="field.type === 'TEXTAREA'">
                <v-textarea 
                  v-model="modelObj[field.model]"
                  outlined
                  dense
                  :label="field.label" 
                  :rules="field.isRequired ? setValidation('requiredValidation') : []"
                  :placeholder="field.label" 
                  :disabled="field.disabled"
                  :readonly="field.readonly">
                </v-textarea>
              </template>
              <template v-if="field.type === 'CHECKBOX'">
                <v-checkbox 
                  v-model="modelObj[field.model]"
                  outlined
                  dense
                  :label="field.label" 
                  :placeholder="field.label" 
                  :disabled="field.disabled"
                  :readonly="field.readonly">
                </v-checkbox>
              </template>
              <template v-if="field.type === 'RADIO'">
                <v-card-subtitle class="pa-0 ma-0">{{ field.label }}</v-card-subtitle>
                <v-radio-group row v-model="modelObj[field.model]" :disabled="field.disabled" mandatory>
                  <v-radio v-for="(option, index) in field.selectItems" :key="index" :label="option.text" :value="option.value"></v-radio>
                </v-radio-group>
              </template>
              <template v-if="field.type === 'SELECT'">
                <template v-if="field.selectType === 'MODULE'">
                  <v-autocomplete
                    v-model="modelObj[field.model]"
                    :items="lookupModuleItems[field.model] || []"
                    outlined
                    dense
                    :rules="field.isRequired ? setValidation('requiredValidation') : []"
                    :item-text="(val) => field.selectModule === 'users' ? (`${val.formdata.name} ${(val.role && val.role.name) ? '(' + val.role.name + ')' : ''}`) : `${val.name}`"
                    item-value="_id"
                    :label="field.label" 
                    :placeholder="field.label" 
                    :disabled="field.disabled"
                    :multiple="field.isMultiselect"
                    :readonly="field.readonly">
                  </v-autocomplete>
                </template>
                <template v-if="field.selectType === 'CUSTOM'" >
                  <v-autocomplete
                    v-model="modelObj[field.model]"
                    :items="field.selectItems"
                    outlined
                    :rules="field.isRequired ? setValidation('requiredValidation') : []"
                    dense
                    :multiple="field.isMultiselect"
                    :label="field.label" 
                    :placeholder="field.label" 
                    :disabled="field.disabled"
                    :readonly="field.readonly">
                  </v-autocomplete>
                </template>
              </template>
              <template v-if="field.type === 'PASSWORD'">
                <v-text-field
                  v-model="modelObj[field.model]"
                  outlined
                  dense
                  :rules="field.isRequired ? setValidation('requiredValidation') : []"
                  :append-icon="field.show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="field.show ? 'text' : 'password'"
                  :label="field.label" 
                  :placeholder="field.label" 
                  :disabled="field.disabled"
                  hint="At least 8 characters"
                  counter
                  @click:append="field.show = !field.show"
                ></v-text-field>
              </template>
              <template  v-if="field.type === 'NUMBER'">
                <v-text-field
                  v-model="modelObj[field.model]"
                  type="number" 
                  outlined
                  dense
                  :rules="field.isRequired ? setValidation('requiredValidation') : []"
                  :label="field.label" 
                  :placeholder="field.label" 
                  :disabled="field.disabled"
                  :readonly="field.readonly">
                </v-text-field>
              </template>
              <template v-if="field.type === 'EMAIL'">
                <v-text-field
                  v-model="modelObj[field.model]"
                  outlined
                  dense
                  :rules="[...setValidation('requiredValidation'), ...setValidation('emailValidation')]"
                  :label="field.label"
                  :placeholder="field.label" 
                  :disabled="field.disabled"
                  :readonly="field.readonly"
                ></v-text-field>
              </template>
              <template v-if="field.type === 'DATE_PICKER'">
                <v-menu v-model="field.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="modelObj[field.model]"
                      :label="field.label"
                      outlined
                      dense
                      :rules="field.isRequired ? setValidation('requiredValidation') : []"
                      hide-details
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                <v-date-picker v-model="modelObj[field.model]" @input="field.menu = false"></v-date-picker>
              </v-menu>
              </template>
              <template v-if="field.type === 'TIME_PICKER'">
                <v-menu ref="menu"
                  v-model="field.menu" 
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="field.time"
                  transition="scale-transition"
                  :rules="field.isRequired ? setValidation('requiredValidation') : []"
                  offset-y 
                  max-width="290px" 
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="field.time" label="Picker in menu" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                <v-time-picker v-if="field.menu" v-model="field.time" full-width @click:minute="$refs.menu.save(field.time)"></v-time-picker>
              </v-menu>
              </template>
              <template v-if="field.type === 'PANEL'">
                <v-card :id="`dropZonePanel_${field._id}`"
                  @dragover="allowDrop($event, `dropZonePanel_${field._id}`)"
                  @dragleave="removeStyle(`dropZonePanel_${field._id}`)"
                  @drop="fieldDroped(`dropZonePanel_${field._id}`)">
                  <v-card-title>
                    <h5>
                      {{field.label || 'Panel Field'}}
                    </h5>
                  </v-card-title>
                  <v-card-text>
                    <form-fields
                      :fields="fields.filter(x => x.panelId === field._id)"
                      :fromPanel="true"
                      :modelObj="{}"
                      :fromBuilder="true" ref="form"
                      @fieldEdit="fieldEdited"
                      @fieldDelete="fieldDeleted">
                    </form-fields>
                  </v-card-text>
                </v-card>
              </template>
              <v-layout justify-end v-if="hoverOnField === field._id && fromBuilder">
                <v-fade-transition>
                  <v-btn style="position: sticky; margin-top: -25px;" text x-small icon color="info" @click="$emit('fieldEdit', field._id)">
                    <v-icon> mdi-pencil</v-icon> 
                  </v-btn>
                </v-fade-transition>
                <v-fade-transition v-if="!field.isinternal">
                  <v-btn style="position: sticky; margin-top: -25px;" text x-small icon color="error" @click="$emit('fieldDelete', field._id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-fade-transition>
              </v-layout>
            </v-col>
          </template>
        </draggable>
      </v-row>
    </v-form>
    <span v-else class="mx-10"> Drop the Fields Here </span>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  props: ['fields','modelObj', 'fromBuilder','fromPanel'],
  data () {
    return{
      lookupModuleItems: [],
      loading: true,
      droppedOnPanel: null,
      hoverOnField: null
    }
  },
  mounted () {
    this.getLookupItemsList()
  },
  components: {
    draggable,
    'form-fields': () => import('@/views/FormBuilder/Builder/Components/FormFields.vue')
  },
  methods: {
    fieldEdited (fieldId) {
      this.$emit('fieldEdit', fieldId)
    },
    fieldDeleted (fieldId) {
      this.$emit('fieldEdit', fieldId)
    },
    allowDrop(event, elementId) {
      event.preventDefault()
      if (elementId !== 'dropZone') this.droppedOnPanel = elementId.replace('dropZonePanel_', '')
      if (this.droppedOnPanel && elementId === 'dropZone') return
      document.querySelector(`#${elementId}`).style.border = "4px dotted blue"
    },
    removeStyle(elementId) {
      if (elementId !== 'dropZone') this.droppedOnPanel = null
      document.querySelector(`#${elementId}`).style.border = "1px solid #ddd"
    },
    fieldDroped(elementId) {
      if (this.droppedOnPanel && elementId === 'dropZone') return
      document.querySelector(`#${elementId}`).style.border = "1px solid #ddd"
      this.$emit('fieldEdit', null, this.droppedOnPanel)
    },
    getLookupItemsList () {
      if (this.fields.length) {
        const selectFields = this.fields.filter(x => x.type === 'SELECT' && x.selectType === 'MODULE')
        if (selectFields.length) {
          selectFields.map(async (x, index) => {
            if (x.selectModule === 'users' && x.filterByUserRoles && x.filterByUserRoles.length) {
              await this.$store.dispatch('getUsersList', { roleIds: x.filterByUserRoles }).then((data) => {
                this.lookupModuleItems[x.model] = data
                if (index === selectFields.length - 1) {
                  this.lookupModuleItems = { ...this.lookupModuleItems }
                  this.loading = false
                }
              })
            } else {
              await this.$_execute('get', x.selectModule).then(({ data }) => {
                this.lookupModuleItems[x.model] = data
                if (selectFields.length - 1 === index) {
                  this.lookupModuleItems = { ...this.lookupModuleItems }
                  this.loading = false
                }
              })
            }
          })
        } else this.loading = false
      } else this.loading = false
    },
  }
}
</script>

<style>

</style>